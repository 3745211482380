@tailwind base;
@tailwind components;
@tailwind utilities;

body {
   padding: 0;
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}

.ocean {
   height: 500px; /* change the height of the waves here */
   width: 100%;
   position: relative;
   bottom: 0;
   left: 0;
   right: 0;
   overflow-x: hidden;
}

.wave {
   background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%23273036' fill-opacity='1' d='M0,96L60,85.3C120,75,240,53,360,80C480,107,600,181,720,192C840,203,960,149,1080,128C1200,107,1320,117,1380,122.7L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z'%3E%3C/path%3E%3C/svg%3E");
   position: absolute;
   width: 200%;
   height: 100%;
   background-size: cover;
   background-repeat: no-repeat;
   animation: wave 10s -3s linear infinite;
   transform: translate3d(0, 0, 0);
   opacity: 1;
}

.wave:nth-of-type(2) {
   bottom: 0;
   animation: wave 18s linear reverse infinite;
   opacity: 0.8;
}

.wave:nth-of-type(3) {
   bottom: 0;
   animation: wave 20s -1s linear infinite;
   opacity: 0.9;
}

@keyframes wave {
   0% {
      transform: translateX(0);
   }
   25% {
      transform: translateX(-25%);
   }
   50% {
      transform: translateX(-50%);
   }
}

.mine {
   /* background: #347fc3; */
   width: 100%;
   overflow-x: hidden;
   overflow-y: hidden;
}

.bar {
   position: relative;
   height: 2px;
   width: 500px;
   margin: 0 auto;
   background: #fff;
   margin-top: 150px;
}

.circle {
   position: absolute;
   top: -30px;
   margin-left: -30px;
   height: 60px;
   width: 60px;
   left: 0;
   background: #fff;
   border-radius: 30%;
   animation: normal;
   -webkit-animation: move 5s infinite;
}

.spinnerP {
   position: absolute;
   top: -35px;
   right: -85px;
   text-transform: uppercase;
   color: #6b7280;
   font-family: helvetica, sans-serif;
   font-weight: bold;
   text-decoration-color: #6b7280;
}

@keyframes move {
   0% {
      left: 0;
   }
   50% {
      left: 100%;
      -webkit-transform: rotate(450deg);
      width: 150px;
      height: 150px;
   }
   75% {
      left: 100%;
      -webkit-transform: rotate(450deg);
      width: 150px;
      height: 150px;
   }
   100% {
      right: 100%;
   }
}

@font-face {
   font-family: "Sail";
   src: url("./fonts/Sail-Regular.ttf") format("truetype");
   /* Add additional font weights and styles if available */
}
@font-face {
   font-family: "Cursiv";
   src: url("./fonts/CURSIV.ttf") format("truetype");
   /* Add additional font weights and styles if available */
}
@font-face {
   font-family: "Gabriel";
   src: url("./fonts/Gabriel.ttf") format("truetype");
   /* Add additional font weights and styles if available */
}
@font-face {
   font-family: "Monotype_Corsiva";
   src: url("./fonts/Monotype Corsiva.ttf");
   /* Add additional font weights and styles if available */
}
@font-face {
   font-family: "Spicy-Rice";
   src: url("./fonts/SpicyRice-Regular.ttf") format("truetype");
   /* Add additional font weights and styles if available */
}
@font-face {
   font-family: "Verdana-Bold";
   src: url("./fonts/Verdana\ Bold.ttf") format("truetype");
   /* Add additional font weights and styles if available */
}
@font-face {
   font-family: "Verdana";
   src: url("./fonts/verdana.ttf") format("truetype");
   /* Add additional font weights and styles if available */
}
@font-face {
   font-family: "Verdana-0";
   src: url("./fonts/VERDANA0.TTF") format("truetype");
   /* Add additional font weights and styles if available */
}
@font-face {
   font-family: "Walt Disney Script";
   src: url("./fonts/Walt\ Disney\ Script.ttf") format("truetype");
   /* Add additional font weights and styles if available */
}
